import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'next-i18next';

import TourCard from 'views/tours-module/TourView/components/TourCard';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import { SliderDots } from 'views/common/components';

const PREFIX = 'RelatedTours';

const classes = {
  itemClass: `${PREFIX}-itemClass`,
  dotsClass: `${PREFIX}-dotsClass`,
  sliderWrap: `${PREFIX}-sliderWrap`,
  arrowsBtnContainer: `${PREFIX}-arrowsBtnContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.itemClass}`]: {
    padding: theme.spacing(0, 0, 0.5, 2),
  },

  [`& .${classes.dotsClass}`]: {
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.sliderWrap}`]: {
    marginLeft: theme.spacing(-2),
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.spacing(3.75),
    },
  },

  [`& .${classes.arrowsBtnContainer}`]: {
    '& .arrow': {
      position: 'absolute',
      top: '50%',
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: '50%',
      backgroundColor: 'rgba(48, 48, 48, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.fogWhite,
      cursor: 'pointer',
      border: 'none',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },

      '&.arrowLeft': {
        left: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
          left: theme.spacing(-1.8),
        },
      },

      '&.arrowRight': {
        right: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
          right: theme.spacing(-3),
        },
      },
      '&.disable': {
        opacity: '0.2',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
      '& > svg': {
        fontSize: theme.typography.pxToRem(32),
      },
    },
  },
}));

const ArrowsBtnGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  return (
    <div className={classes.arrowsBtnContainer}>
      <button
        className={`arrow arrowLeft ${currentSlide === 0 ? 'disable' : ''}`}
        onClick={() => previous()}
        type="button">
        <ChevronLeftIcon />
      </button>
      <button
        className={`arrow arrowRight ${
          currentSlide === Math.floor(totalItems / slidesToShow)
            ? 'disable'
            : ''
        }`}
        onClick={() => next()}
        type="button">
        <ChevronRightIcon />
      </button>
    </div>
  );
};

const RelatedTours = (props) => {
  const {
    toursArr, setIsMoving, deviceType, ssrRender,
  } = props;

  const { t } = useTranslation('fe_er_motorcycle_tours');

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  let showingCardCount = toursArr.length;
  if (ssrRender) {
    if (deviceType == 'desktop') {
      showingCardCount = 3;
    }
    if (deviceType == 'tablet') {
      showingCardCount = 2;
    }
    if (deviceType == 'mobile') {
      showingCardCount = 1;
    }
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 1199, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 600 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Root>
      <Box align={smallScreen ? 'center' : 'left'} mb={smallScreen ? 1 : 2}>
        <Typography500 variant="h3">
          {t('fe_er_motorcycle_tours:related_Tours_title')}
        </Typography500>
      </Box>

      <Box className={classes.sliderWrap}>
        <Carousel
          swipeable
          draggable={false}
          ssr={ssrRender} // means to render carousel on server-side.
          renderDotsOutside
          keyBoardControl
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          itemClass={classes.itemClass}
          dotListClass={classes.dotsClass}
          responsive={responsive}
          additionalTransfrom={!smallScreen && -4}
          beforeChange={() => setIsMoving(true)}
          afterChange={() => setIsMoving(false)}
          minimumTouchDrag={20}
          customTransition="transform 200ms ease-in"
          deviceType={deviceType}
          renderButtonGroupOutside
          arrows={false}
          autoPlay={false}
          shouldResetAutoplay={false}
          customButtonGroup={
            toursArr?.length > 4
            && (!smallScreen ? (
              <ArrowsBtnGroup />
            ) : (
              <SliderDots
                slidesArray={toursArr?.slice(0, showingCardCount)}
                length={showingCardCount}
              />
            ))
          }>
          {toursArr
            && toursArr
              .slice(0, showingCardCount)
              .map((tour) => <TourCard tour={tour} key={tour.name} />)}
        </Carousel>
      </Box>
    </Root>
  );
};

export default memo(RelatedTours);
