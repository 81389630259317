import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import HeaderAndDescription from 'views/common/components/UI/HeaderAndDescription';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

const PREFIX = 'TextBox';

const classes = {
  textBox: `${PREFIX}-textBox`,
  upper: `${PREFIX}-upper`,
  left: `${PREFIX}-left`,
  btn: `${PREFIX}-btn`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Box)(({ theme }) => ({
  [`&.${classes.textBox}`]: {
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.grey['100']}`,
    borderRadius: 8,
  },

  [`& .${classes.upper}`]: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  [`& .${classes.left}`]: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'flex-start',
  },

  [`& .${classes.btn}`]: {
    textDecoration: 'underline',
    marginLeft: theme.spacing(3),
    fontWeight: 700,
  },
}));

const TextBox = (props) => {
  const {
    children,
    icon,
    iconColor,
    title,
    price,
    desc,
    btnText,
    btnOnClick,
    btnPath,
    className,
  } = props;

  return (
    <Root className={`${classes.textBox} ${className}`}>
      <Box className={classes.upper}>
        <Box className={classes.left}>
          <HeaderAndDescription
            icon={icon}
            iconColor={iconColor}
            title={title}
            price={price}
            desc={desc}
          />
        </Box>

        {btnText && (
          <Button
            color="primary"
            onClick={btnOnClick}
            className={classes.btn}
            component={btnPath ? NavigatorLink : 'button'}
            href={btnPath}>
            {btnText}
          </Button>
        )}
      </Box>

      {children}
    </Root>
  );
};

export default TextBox;
